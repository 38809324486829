<template>
  <v-menu
    v-model="datePickerMenu"
    :close-on-click="false"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
    content-class="bg-white"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-row>
        <v-col
          cols="12"
          lg="6">
          <v-text-field
            v-model="dateText"
            :label="label"
            outlined
            dense
            readonly
            color="secondary"
            :disabled="disabled"
            v-bind="attrs"
            hide-details
            v-on="on" />
        </v-col>
        <v-col
          cols="12"
          lg="6">
          <v-text-field
            v-model="dateTime.time"
            label="Time"
            outlined
            dense
            readonly
            color="secondary"
            :disabled="disabled"
            v-bind="attrs"
            hide-details
            v-on="on" />
        </v-col>
      </v-row>
    </template>
    <v-date-picker
      v-model="dateTime.date"
      color="secondary"
      class="mr-2">
      <v-spacer />
      <v-btn
        text
        small
        color="secondary"
        @click="dateTimePickerClose()">
        Close
      </v-btn>
      <v-btn
        text
        small
        color="secondary"
        @click="dateTimePickerSubmit()">
        Submit
      </v-btn>
    </v-date-picker>
    <v-time-picker
      v-model="dateTime.time"
      color="secondary"
      format="24hr">
      <v-spacer />
      <v-btn
        text
        small
        color="secondary"
        @click="dateTimePickerClose()">
        Close
      </v-btn>
      <v-btn
        text
        small
        color="secondary"
        @click="dateTimePickerSubmit()">
        Submit
      </v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Date'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      datePickerMenu: false,
      date: {
        date: this.$dayjs().format('YYYY-MM-DD'),
        time: this.$dayjs().format('HH:mm'),
        utc: this.$dayjs().utc().toISOString()
      }
    }
  },
  computed: {
    dateTime: {
      get () {
        return this.date
      },
      set (val) {
        this.date = val
      }
    },
    dateText () {
      return this.$dayjs(this.dateTime.date).format('DD-MM-YYYY')
    }
  },
  mounted () {
    this.initDate()
  },
  methods: {
    initDate () {
      if (this.value) {
        this.date = {
          date: this.$dayjs(this.value).format('YYYY-MM-DD'),
          time: this.$dayjs(this.value).format('HH:mm'),
          utc: this.$dayjs(this.value).utc().toISOString()
        }
      }
    },
    dateTimePickerSubmit () {
      this.$emit('on-submit', {
        date: this.dateTime.date,
        time: this.dateTime.time,
        utc: this.$dayjs(`${this.dateTime.date} ${this.dateTime.time}`).utc().toISOString()
      })
      this.datePickerMenu = false
    },
    dateTimePickerClose () {
      if (this.value) {
        this.dateTime = {
          date: this.$dayjs(this.value).format('YYYY-MM-DD'),
          time: this.$dayjs(this.value).format('HH:mm'),
          utc: this.$dayjs(this.value).utc().toISOString()
        }
      } else {
        this.dateTime = {
          date: this.$dayjs().format('YYYY-MM-DD'),
          time: this.$dayjs().format('HH:mm'),
          utc: this.$dayjs().utc().toISOString()
        }
      }

      this.datePickerMenu = false
    }
  }
}
</script>

<style  scoped>
.bg-white {
  background-color: white !important;
}
</style>
