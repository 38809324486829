<template>
  <div>
    <v-dialog
      v-model="isSelectImage"
      width="fit-content">
      <image-modal :image="selectedImage" />
    </v-dialog>
    <v-data-table
      :headers="header"
      :items="branchData"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      height="70vh"
      :search="inputSearch"
      class="elevation-3"
      hide-default-footer
      @page-count="pageCount = $event">
      <template v-slot:top>
        <v-toolbar flat>
          <v-row
            justify="space-between"
            no-gutters>
            <v-col cols="3">
              <v-toolbar-title>
                <span class="capitalize">
                  Branches
                </span>
              </v-toolbar-title>
            </v-col>
            <v-col cols="9">
              <v-row
                justify="end"
                no-gutters>
                <v-col cols="3">
                  <v-select
                    v-model="selectedBrand"
                    :items="brands"
                    label="Brand"
                    dense
                    outlined
                    hide-details
                    class="mr-1" />
                </v-col>
                <v-col cols="4">
                  <search-box
                    v-model="inputSearch"
                    class="ml-1"
                    @on-search="getBranches()" />
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    class="ml-2"
                    color="primary"
                    @click="dialogToggle()">
                    Create
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    color="success"
                    class="ml-2"
                    width="110px"
                    :disabled="disableSave"
                    @click="savePriority()">
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <v-card>
              <branch-form-data
                v-model="formData"
                :title="formTitle"
                :brands="brands"
                @on-save="saveForm()"
                @on-close="closeFormModal()" />
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:body>
        <draggable
          :list="branchData"
          group="branches"
          tag="tbody">
          <tr
            v-for="(item, index) in branchData"
            :key="`branch-index-${index}`">
            <td class="text-center vertical-top pa-4">
              <img
                v-if="showImage(item.mapImageUrl)"
                class="mini-image"
                :src="item.mapImageUrl"
                :alt="item.name"
                @click="showImageModal(item.mapImageUrl)">
              <span v-else>No Image</span>
            </td>
            <td class="text-center vertical-top pa-4">
              {{ item.name }}
            </td>
            <td class="vertical-top pa-4">
              {{ item.location }}
            </td>
            <td class="text-center vertical-top pa-4">
              <div
                v-for="(open, i) in item.opens"
                :key="`open-${i}`">
                {{ open.day }} <br> {{ open.time }}
              </div>
            </td>
            <td class="text-center vertical-top pa-4">
              {{ item.tel }}
            </td>
            <td class="text-center vertical-top pa-4">
              {{ item.brand }}
            </td>
            <td class="text-center vertical-top pa-4">
              {{ item.priority }}
            </td>
            <td class="text-start vertical-top pa-4">
              <v-icon
                class="mr-2"
                small
                @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <gw-icon-hover
                class="sku-table-bin"
                icon-name="mdi-delete"
                icon-hover="mdi-delete-empty"
                small
                @click="removeBranch(item)" />
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Draggable from 'vuedraggable'
import SearchBox from '@/components/SearchBox.vue'
import WebManagementProvider from '@/resources/WebManagementProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import ImageModal from './ImageModal.vue'
import BranchFormData from './BranchFormData.vue'

const ProductAttributeService = new ProductAttributeProvider()
const WebManagementService = new WebManagementProvider()

export default {
  components: { Draggable, SearchBox, BranchFormData, ImageModal },
  data: () => ({
    header: [
      {
        text: 'Image',
        value: 'image',
        width: '110px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Name',
        value: 'name',
        width: '200px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Location',
        value: 'location',
        width: '250px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Open',
        value: 'open',
        width: '180px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Tel',
        value: 'tel',
        width: '100px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Brand',
        value: 'brand',
        width: '100px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Priority',
        value: 'priority',
        width: '60px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '100px',
        align: 'start'
      }
    ],
    inputSearch: '',
    selectedBrand: null,
    brands: [],
    options: {
      page: 1,
      itemsPerPage: 9999,
      sortBy: ['priority'],
      sortDesc: [false]
    },
    pageCount: 0,
    dialog: false,
    isEdited: false,
    loading: false,
    formData: {
      id: '',
      status: 'active',
      mapImageUrl: '',
      name: '',
      location: '',
      opens: [
        { day: '', time: '' }
      ],
      tel: '',
      brand: null,
      priority: 99999
    },
    memoBranches: [],
    branchData: [],
    isSelectImage: false,
    selectedImage: null
  }),
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Branch' : 'New Branch'
    },
    disableSave () {
      return JSON.stringify(this.memoBranches) === JSON.stringify(this.branchData) || this.loading
    }
  },
  watch: {
    selectedBrand (val) {
      this.formData.brand = val
      this.getBranches()
    }
  },
  async mounted () {
    await Promise.all([
      this.getBranches(),
      this.getBrand()
    ])
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    async getBrand () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 999
        })

        this.brands = data.results.map((brand) => brand.name)
      } catch (error) {
        console.error('getAttribute: ', error)
      }
    },
    showImage (image) {
      if (image !== '') {
        return true
      }

      return false
    },
    showImageModal (image) {
      this.isSelectImage = true
      this.selectedImage = image
    },
    dialogToggle () {
      this.formData.priority = this.branchData.length + 1
      this.dialog = true
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.clearForm()
    },
    editItem (item) {
      this.isEdited = true
      this.formData = { ...item }
      this.dialog = true
    },
    clearForm () {
      this.formData = {
        id: '',
        status: 'active',
        mapImageUrl: '',
        name: '',
        location: '',
        opens: [
          { day: '', time: '' }
        ],
        tel: '',
        brand: this.selectedBrand,
        priority: 99999
      }
    },
    async getBranches () {
      try {
        this.loading = true

        const { data } = await WebManagementService.getBranches({
          search: this.inputSearch,
          brand: this.selectedBrand
        })
        this.branchData = data
        this.memoBranches = new Array(...JSON.parse(JSON.stringify(data)))
      } catch (error) {
        console.error('confirmRemoveBranch', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    removeBranch (item) {
      this.setModal({
        value: true,
        title: 'Remove Branch',
        message: 'Do you want to remove the Branch?',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveBranch(item.id)
      })
    },
    async confirmRemoveBranch (id) {
      try {
        this.loading = true

        const { data } = await WebManagementService.deleteBranch(id)

        if (data) {
          this.setSnackbar({
            value: true,
            message: 'Branch removed.',
            type: 'success'
          })
          if (this.options.page > 1 && this.branchData.length < 2) {
            this.options.page--
          } else {
            this.getBranches()
          }
        } else {
          const error = {
            code: 400,
            message: 'Branch ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        console.error('confirmRemoveBranch', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async saveForm () {
      let isSuccess = false
      let message = 'created'
      try {
        if (this.isEdited) {
          const { data } = await WebManagementService.updateBranch(this.formData)

          if (data) {
            isSuccess = true
          }
          message = 'updated'
        } else {
          const { data } = await WebManagementService.createBranch(this.formData)

          if (data) {
            isSuccess = true
          }
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
        console.error('saveForm', error)
      } finally {
        if (isSuccess) {
          this.setSnackbar({
            value: true,
            message: `Branch ${message}`,
            type: 'success'
          })
          this.closeFormModal()
          this.getBranches()
        } else {
          this.setSnackbar({
            value: true,
            message: `Branch ${message} failed.`,
            type: 'error'
          })
        }
      }
    },
    async savePriority () {
      try {
        const branches = JSON.parse(JSON.stringify(this.branchData))
        const rePriority = branches.map((branch, index) => ({
          ...branch,
          priority: index + 1
        }))

        const unpromised = []
        const priorityPayloads = []

        for (const memo of this.memoBranches) {
          const branch = rePriority.find((col) => col.id === memo.id)

          if (branch.id) {
            if (branch.priority !== memo.priority) {
              priorityPayloads.push({ id: branch.id, priority: branch.priority })
            }
          }
        }

        if (priorityPayloads.length) {
          unpromised.push(WebManagementService.updateBranchPriority(priorityPayloads))
        }

        await Promise.all(unpromised)

        this.getBranches()
      } catch (error) {
        console.error('savePriority', error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>
.mini-image {
  cursor: pointer;
}
</style>
