import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class WebManagementProvider extends HttpRequest {
  getCollections (query) {
    this.setHeader(getAuthToken())
    return this.get('web-collections', query)
  }

  getCollectionById (id) {
    this.setHeader(getAuthToken())
    return this.get(`web-collections/${id}`)
  }

  createCollection (collectionForm) {
    this.setHeader(getAuthToken())
    return this.post('web-collections', collectionForm)
  }

  updateCollection (collectionForm) {
    this.setHeader(getAuthToken())
    return this.put(`web-collections/${collectionForm.id}`, collectionForm)
  }

  updateCollectionPriority (payloads) {
    this.setHeader(getAuthToken())
    return this.patch('web-collections/priority', { payloads })
  }

  deleteCollection (id) {
    this.setHeader(getAuthToken())
    return this.delete('web-collections', id)
  }

  getCategories (query) {
    this.setHeader(getAuthToken())
    return this.get('web-categories', query)
  }

  getCategoryById (id) {
    this.setHeader(getAuthToken())
    return this.get(`web-categories/${id}`)
  }

  createCategory (categoryForm) {
    this.setHeader(getAuthToken())
    return this.post('web-categories', categoryForm)
  }

  updateCategory (categoryForm) {
    this.setHeader(getAuthToken())
    return this.put(`web-categories/${categoryForm.id}`, categoryForm)
  }

  updateCategoryPriority (payloads) {
    this.setHeader(getAuthToken())
    return this.patch('web-categories/priority', { payloads })
  }

  deleteCategory (id) {
    this.setHeader(getAuthToken())
    return this.delete('web-categories', id)
  }

  getBranches (query) {
    this.setHeader(getAuthToken())
    return this.get('web-branches', query)
  }

  getBranchById (id) {
    this.setHeader(getAuthToken())
    return this.get(`web-branches/${id}`)
  }

  createBranch (branchForm) {
    this.setHeader(getAuthToken())
    return this.post('web-branches', branchForm)
  }

  updateBranch (branchForm) {
    this.setHeader(getAuthToken())
    return this.put(`web-branches/${branchForm.id}`, branchForm)
  }

  updateBranchPriority (payloads) {
    this.setHeader(getAuthToken())
    return this.patch('web-branches/priority', { payloads })
  }

  deleteBranch (id) {
    this.setHeader(getAuthToken())
    return this.delete('web-branches', id)
  }

  getCampaignTags (query) {
    this.setHeader(getAuthToken())
    return this.get('web-campaign-tags', query)
  }

  getCampaignTagById (id) {
    this.setHeader(getAuthToken())
    return this.get(`web-campaign-tags/${id}`)
  }

  createCampaignTag (payload) {
    this.setHeader(getAuthToken())
    return this.post('web-campaign-tags', payload)
  }

  updateCampaignTag (payload) {
    this.setHeader(getAuthToken())
    return this.put(`web-campaign-tags/${payload.id}`, payload)
  }

  deleteCampaignTag (id) {
    this.setHeader(getAuthToken())
    return this.delete('web-campaign-tags', id)
  }

  getCartLimitations (query) {
    this.setHeader(getAuthToken())
    return this.get('web-cart-limitations', query)
  }

  getCartLimitationById (id) {
    this.setHeader(getAuthToken())
    return this.get(`web-cart-limitations/${id}`)
  }

  createCartLimitation (payload) {
    this.setHeader(getAuthToken())
    return this.post('web-cart-limitations', payload)
  }

  updateCartLimitation (payload) {
    this.setHeader(getAuthToken())
    return this.put(`web-cart-limitations/${payload.id}`, payload)
  }

  deleteCartLimitation (id) {
    this.setHeader(getAuthToken())
    return this.delete('web-cart-limitations', id)
  }
}

export default WebManagementProvider
