<template>
  <div class="image-container">
    <img
      :src="image"
      alt="Image">
  </div>
</template>

<script>
  export default {
    props: {
      image: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style scoped>
  .image-container {
    position: relative;
    width: fit-content;
    height: fit-content;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.5);
    left: 50%;
    transform: translateX(-50%);
  }
</style>
