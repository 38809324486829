<template>
  <v-container
    class="web-management-container elevation-5"
    fluid>
    <v-row>
      <v-col cols="12">
        <h2>Web Management</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-list class="rounded px-2 elevation-3">
          <v-list-item-group v-model="selectedMenu">
            <v-list-item
              v-for="(menu, i) in menus"
              :key="i">
              <v-list-item-icon>
                <v-icon v-text="menu.icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="menu.text" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="9">
        <collections v-if="checkMenu(0)" />
        <categories v-if="checkMenu(1)" />
        <branches v-if="checkMenu(2)" />
        <campaign-tags v-if="checkMenu(3)" />
        <cart-limitation v-if="checkMenu(4)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CampaignTags from '../components/CampaignTags.vue'
import Branches from '../components/Branches.vue'
import Categories from '../components/Categories.vue'
import Collections from '../components/Collections.vue'
import CartLimitation from '../components/CartLimitation.vue'

export default {
  components: { Collections, Categories, Branches, CampaignTags, CartLimitation },
  data: () => ({
    selectedMenu: 0,
    menus: [
      {
        icon: 'mdi-image-multiple',
        text: 'Collections'
      },
      {
        icon: 'mdi-shape',
        text: 'Categories'
      },
      {
        icon: 'mdi-warehouse',
        text: 'Branches'
      },
      {
        icon: 'mdi-tag',
        text: 'Campaign Tags'
      },
      {
        icon: 'mdi-cart',
        text: 'Cart Limitation'
      }
    ]
  }),
  methods: {
    checkMenu (index) {
      if (this.selectedMenu === index) {
        return true
      }

      return false
    }
  }
}
</script>

<style scoped>
.web-management-container {
  background-color: #fff;
  border-radius: 5px;
  height: fit-content;
}
.content {
  background-color: #fff;
}
</style>
