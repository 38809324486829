<template>
  <validation-observer
    ref="attributeForm"
    tag="form"
    @submit.prevent="save()">
    <v-card-title>
      <span class="headline">{{ title }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <gw-text-field
              v-model="formData.name"
              :rules="textFieldRules"
              :readonly="modalLoading"
              outlined
              dense
              hide-details
              label="Name" />
          </v-col>
          <v-col cols="6">
            <gw-text-field
              v-model="formData.tel"
              :readonly="modalLoading"
              outlined
              dense
              hide-details
              label="Telephone No." />
          </v-col>
          <v-col cols="6">
            <gw-autocomplete
              v-model="formData.brand"
              :items="brands"
              label="Brand"
              outlined
              dense
              hide-details
              rules="required"
              :readonly="modalLoading">
            </gw-autocomplete>
          </v-col>
          <v-col cols="12">
            <gw-text-field
              v-model="formData.location"
              :rules="textFieldRules"
              :readonly="modalLoading"
              outlined
              dense
              hide-details
              label="Location" />
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col align-self="center">
                <h3>Opens</h3>
              </v-col>
              <v-spacer />
              <v-col class="text-end">
                <v-btn
                  color="secondary"
                  @click="addOpen()">
                  <v-icon>
                    mdi-plus
                  </v-icon> Add
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-for="(open, i) in formData.opens"
              :key="`open-${i}`">
              <v-col cols="5">
                <gw-text-field
                  v-model="open.day"
                  :readonly="modalLoading"
                  outlined
                  dense
                  hide-details
                  label="Day" />
              </v-col>
              <v-col cols="5">
                <gw-text-field
                  v-model="open.time"
                  :readonly="modalLoading"
                  outlined
                  dense
                  hide-details
                  label="Time" />
              </v-col>
              <v-col
                cols="2"
                class="text-center">
                <v-btn
                  color="error"
                  icon
                  @click="deleteOpen(i)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <gw-drop-file
              v-model="formData.mapImageUrl"
              :loading="modalLoading"
              :img-aspect-ratio="0.75"
              label="Map Image"
              s3-path="/gw-web-branch-map" />
          </v-col>
          <v-col cols="12">
            <gw-text-field
              v-model="formData.priority"
              :rules="textFieldRules"
              readonly
              outlined
              dense
              hide-details
              label="Priority" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="blue darken-1"
        text
        @click="closeFormModal()">
        Cancel
      </v-btn>
      <v-btn
        :loading="modalLoading"
        color="blue darken-1"
        type="submit"
        text>
        Save
      </v-btn>
    </v-card-actions>
  </validation-observer>
</template>

<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      title: {
        type: String,
        default: ''
      },
      brands: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      modalLoading: false,
      textFieldRules: {
        required: true
      }
    }),
    computed: {
      formData: {
        get () {
          return this.value
        },
        set (val) {
          this.value = val
          this.$emit('input', this.value)
        }
      }
    },
    methods: {
      addOpen () {
        this.formData.opens.push({ day: '', time: '' })
      },
      deleteOpen (index) {
        if (this.formData.opens.length === 1) {
          this.formData.opens.splice(index, 1, { day: '', time: '' })
        } else {
          this.formData.opens.splice(index, 1)
        }
      },
      async save () {
        this.modalLoading = true
        const valid = await this.$refs.attributeForm.validate()
        if (valid) {
          this.$emit('on-save')
        }
        this.modalLoading = false
      },
      closeFormModal () {
        this.$emit('on-close')
      }
    }
  }
</script>
