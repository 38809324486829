var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.campaignTags,"items-per-page":-1,"loading":_vm.loading,"height":"70vh","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{attrs:{"justify":"space-between","no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-toolbar-title',[_c('span',{staticClass:"capitalize"},[_vm._v(" Campaign Tags ")])])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"mr-1",attrs:{"items":_vm.brands,"label":"Brand","dense":"","outlined":"","hide-details":""},model:{value:(_vm.selectedBrand),callback:function ($$v) {_vm.selectedBrand=$$v},expression:"selectedBrand"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('search-box',{staticClass:"ml-1",on:{"on-search":function($event){return _vm.getCampaignTags()}},model:{value:(_vm.inputSearch),callback:function ($$v) {_vm.inputSearch=$$v},expression:"inputSearch"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.dialogToggle()}}},[_vm._v(" Create ")])],1)],1)],1)],1),(_vm.dialog)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('campaign-tag-form-data',{attrs:{"title":_vm.formTitle,"tags":_vm.tags,"brands":_vm.brands},on:{"on-save":function($event){return _vm.saveForm()},"on-close":function($event){return _vm.closeFormModal()}},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)],1):_vm._e()],1)]},proxy:true},{key:"item.tags",fn:function(ref){
var item = ref.item;
return _vm._l((item.tags),function(tag,tIndex){return _c('v-chip',{key:("tag-chip-" + tIndex),staticClass:"ma-1",attrs:{"color":"secondary","text-color":"white","small":"","label":""}},[_vm._v(" "+_vm._s(tag.name)+" ")])})}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.date))+" ")]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.note)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('gw-icon-hover',{staticClass:"sku-table-bin",attrs:{"icon-name":"mdi-delete","icon-hover":"mdi-delete-empty","small":""},on:{"click":function($event){return _vm.removeCampaignTag(item)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }