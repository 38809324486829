<template>
  <div>
    <v-dialog
      v-model="isSelectImage"
      width="fit-content">
      <image-modal :image="selectedImage" />
    </v-dialog>
    <v-data-table
      :headers="header"
      :items="collectionData"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      height="70vh"
      :search="inputSearch"
      class="elevation-3"
      hide-default-footer
      @page-count="pageCount = $event">
      <template v-slot:top>
        <v-toolbar flat>
          <v-row
            justify="space-between"
            no-gutters>
            <v-col cols="3">
              <v-toolbar-title>
                <span class="capitalize">
                  Collections
                </span>
              </v-toolbar-title>
            </v-col>
            <v-col cols="9">
              <v-row
                justify="end"
                no-gutters>
                <v-col cols="3">
                  <v-select
                    v-model="selectedBrand"
                    :items="brands"
                    label="Brand"
                    dense
                    outlined
                    hide-details
                    class="mr-1" />
                </v-col>
                <v-col cols="4">
                  <search-box
                    v-model="inputSearch"
                    class="ml-1"
                    @on-search="getCollections()" />
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    class="ml-2"
                    color="primary"
                    @click="dialogToggle()">
                    Create
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    color="success"
                    class="ml-2"
                    width="110px"
                    :disabled="disableSave"
                    @click="savePriority()">
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <v-card>
              <form-data
                v-model="formData"
                :title="formTitle"
                :tags="tags"
                :categories="categories"
                :brands="brands"
                @on-save="saveForm()"
                @on-close="closeFormModal()" />
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:body>
        <draggable
          :list="collectionData"
          group="collections"
          tag="tbody">
          <tr
            v-for="(item, index) in collectionData"
            :key="`collection-index-${index}`">
            <td class="text-center vertical-top pa-4">
              {{ item.path }}
            </td>
            <td class="text-center vertical-top pa-4">
              {{ item.title }}
            </td>
            <td class="text-center vertical-top pa-4">
              {{ item.available }}
            </td>
            <td class="text-center vertical-top pa-4">
              {{ item.category }}
            </td>
            <td class="text-center vertical-top pa-4">
              {{ item.brand }}
            </td>
            <td class="text-center vertical-top pa-4">
              <v-icon
                v-if="item.useTag"
                color="success">
                mdi-check
              </v-icon>
              <v-icon
                v-else
                color="error">
                mdi-close
              </v-icon>
            </td>
            <td class="text-start vertical-top pa-4">
              <v-chip
                v-for="(tag, tIndex) in item.tags"
                :key="`tag-chip-${tIndex}`"
                class="ma-1"
                color="secondary"
                text-color="white"
                small
                label>
                {{ tag }}
              </v-chip>
            </td>
            <td class="text-center vertical-top pa-4">
              <v-icon
                v-if="item.countDownImage"
                color="success">
                mdi-check
              </v-icon>
              <v-icon
                v-else
                color="error">
                mdi-close
              </v-icon>
            </td>
            <td class="text-center vertical-top pa-4">
              <img
                v-if="showImage(item.image)"
                class="mini-image"
                :src="item.image"
                :alt="item.title"
                @click="showImageModal(item.image)">
              <span v-else>No Image</span>
            </td>
            <td class="text-center vertical-top pa-4">
              <img
                v-if="showImage(item.socialImage)"
                class="mini-image"
                :src="item.socialImage"
                :alt="item.title"
                @click="showImageModal(item.socialImage)">
              <span v-else>No Image</span>
            </td>
            <td class="text-center vertical-top pa-4">
              <v-icon
                v-if="item.isOnWeb"
                color="success">
                mdi-check
              </v-icon>
              <v-icon
                v-else
                color="error">
                mdi-close
              </v-icon>
            </td>
            <td class="text-center vertical-top pa-4">
              <v-icon
                v-if="item.isOnBot"
                color="success">
                mdi-check
              </v-icon>
              <v-icon
                v-else
                color="error">
                mdi-close
              </v-icon>
            </td>
            <td class="text-center vertical-top pa-4">
              {{ item.priority }}
            </td>
            <td class="text-start vertical-top pa-4">
              <v-icon
                class="mr-2"
                small
                @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <gw-icon-hover
                class="sku-table-bin"
                icon-name="mdi-delete"
                icon-hover="mdi-delete-empty"
                small
                @click="removeCollection(item)" />
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Draggable from 'vuedraggable'
import SearchBox from '@/components/SearchBox.vue'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import WebManagementProvider from '@/resources/WebManagementProvider'
import FormData from './FormData.vue'
import ImageModal from './ImageModal.vue'

const ProductAttributeService = new ProductAttributeProvider()
const WebManagementService = new WebManagementProvider()

export default {
  components: { Draggable, SearchBox, FormData, ImageModal },
  data: () => ({
    header: [
      {
        text: 'Path',
        value: 'path',
        width: '150px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Title',
        value: 'title',
        width: '150px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Available',
        value: 'available',
        width: '150px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Category',
        value: 'category',
        width: '100px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Brand',
        value: 'brand',
        width: '100px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Use Tag',
        value: 'useTag',
        width: '80px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Tags',
        value: 'tags',
        width: '180px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Countdown Image',
        value: 'countDownImage',
        width: '150px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Image',
        value: 'image',
        width: '110px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Social Image',
        value: 'socialImage',
        width: '110px',
        align: 'center',
        sortable: false
      },
      {
        text: 'On Web',
        value: 'isOnWeb',
        width: '80px',
        align: 'start',
        sortable: false
      },
      {
        text: 'On Bot',
        value: 'isOnBot',
        width: '80px',
        align: 'start',
        sortable: false
      },
      {
        text: 'Priority',
        value: 'priority',
        width: '60px',
        align: 'center',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '100px',
        align: 'start'
      }
    ],
    inputSearch: '',
    selectedBrand: null,
    brands: [],
    options: {
      page: 1,
      itemsPerPage: 9999,
      sortBy: ['priority'],
      sortDesc: [false]
    },
    pageCount: 0,
    dialog: false,
    isEdited: false,
    loading: false,
    formData: {
      id: '',
      status: 'active',
      path: '',
      title: '',
      available: '',
      category: '',
      useTag: false,
      tags: [],
      countDownImage: false,
      image: '',
      socialImage: '',
      isOnWeb: false,
      isOnBot: false,
      brand: null,
      priority: 99999
    },
    memoCollections: [],
    collectionData: [],
    tags: [],
    categories: [],
    isSelectImage: false,
    selectedImage: null
  }),
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Collection' : 'New Collection'
    },
    disableSave () {
      return JSON.stringify(this.memoCollections) === JSON.stringify(this.collectionData) || this.loading
    }
  },
  watch: {
    selectedBrand (val) {
      this.formData.brand = val
      this.getCollections()
    }
  },
  async mounted () {
    await Promise.all([
      this.getCollections(),
      this.getTags(),
      this.getCategories(),
      this.getBrand()
    ])
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    showImage (image) {
      if (image !== '') {
        return true
      }

      return false
    },
    showImageModal (image) {
      this.isSelectImage = true
      this.selectedImage = image
    },
    dialogToggle () {
      this.formData.priority = this.collectionData.length + 1
      this.dialog = true
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.clearForm()
    },
    editItem (item) {
      this.isEdited = true
      this.formData = { ...item }
      this.dialog = true
    },
    clearForm () {
      this.formData = {
        id: '',
        status: 'active',
        path: '',
        title: '',
        available: '',
        category: '',
        useTag: false,
        tags: [],
        countDownImage: false,
        image: '',
        socialImage: '',
        isOnWeb: false,
        isOnBot: false,
        brand: this.selectedBrand,
        priority: 99999
      }
    },
    async getBrand () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 999
        })

        this.brands = data.results.map((brand) => brand.name)
      } catch (error) {
        console.error('getAttribute: ', error)
      }
    },
    async getTags () {
      try {
        this.loading = true

        const { data } = await ProductAttributeService.getProductAttribute('tags', {
          page: 1,
          itemsPerPage: 9999,
          sortBy: ['id'],
          sortDesc: [true]
        })

        this.tags = data.results.map((r) => ({
          id: r.id,
          name: r.name,
          status: 'active'
        }))
      } catch (error) {
        console.error('getTags: ', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getCategories () {
      try {
        this.loading = true

        const { data } = await ProductAttributeService.getProductAttribute('categories', {
          page: 1,
          itemsPerPage: 9999,
          sortBy: ['id'],
          sortDesc: [true]
        })

        this.categories = data.results.map((r) => ({
          id: r.id,
          name: r.name,
          status: 'active'
        }))
      } catch (error) {
        console.error('getCategories: ', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getCollections () {
      try {
        this.loading = true

        const { data } = await WebManagementService.getCollections({
          search: this.inputSearch,
          brand: this.selectedBrand
        })
        this.collectionData = data
        this.memoCollections = new Array(...JSON.parse(JSON.stringify(data)))
      } catch (error) {
        console.error('confirmRemoveCollection', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    removeCollection (item) {
      this.setModal({
        value: true,
        title: 'Remove Collection',
        message: 'Do you want to remove the Collection?',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveCollection(item.id)
      })
    },
    async confirmRemoveCollection (id) {
      try {
        this.loading = true

        const { data } = await WebManagementService.deleteCollection(id)

        if (data) {
          this.setSnackbar({
            value: true,
            message: 'Collection removed.',
            type: 'success'
          })
          if (this.options.page > 1 && this.collectionData.length < 2) {
            this.options.page--
          } else {
            this.getCollections()
          }
        } else {
          const error = {
            code: 400,
            message: 'Collection ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        console.error('confirmRemoveCollection', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async saveForm () {
      let isSuccess = false
      let message = 'created'
      try {
        if (this.isEdited) {
          const { data } = await WebManagementService.updateCollection(this.formData)

          if (data) {
            isSuccess = true
          }
          message = 'updated'
        } else {
          const { data } = await WebManagementService.createCollection(this.formData)

          if (data) {
            isSuccess = true
          }
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
        console.error('saveForm', error)
      } finally {
        if (isSuccess) {
          this.setSnackbar({
            value: true,
            message: `Collection ${message}`,
            type: 'success'
          })
          this.closeFormModal()
          this.getCollections()
        } else {
          this.setSnackbar({
            value: true,
            message: `Collection ${message} failed.`,
            type: 'error'
          })
        }
      }
    },
    async savePriority () {
      try {
        const collections = JSON.parse(JSON.stringify(this.collectionData))
        const rePriority = collections.map((collection, index) => ({
          ...collection,
          priority: index + 1
        }))

        const unpromised = []
        const priorityPayloads = []

        for (const memo of this.memoCollections) {
          const collection = rePriority.find((col) => col.id === memo.id)

          if (collection.id) {
            if (collection.priority !== memo.priority) {
              priorityPayloads.push({ id: collection.id, priority: collection.priority })
            }
          }
        }

        if (priorityPayloads.length) {
          unpromised.push(WebManagementService.updateCollectionPriority(priorityPayloads))
        }

        await Promise.all(unpromised)

        this.getCollections()
      } catch (error) {
        console.error('savePriority', error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>
.mini-image {
  cursor: pointer;
}
</style>
