<template>
  <validation-observer
    ref="attributeForm"
    tag="form"
    @submit.prevent="save()">
    <v-card-title>
      <span class="headline">{{ title }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <gw-text-field
              v-model="formData.title"
              :rules="textFieldRules"
              :readonly="modalLoading"
              outlined
              dense
              hide-details
              label="Title" />
          </v-col>
          <v-col cols="6">
            <gw-text-field
              v-model="formData.path"
              :rules="textFieldRules"
              :readonly="modalLoading"
              outlined
              dense
              hide-details
              label="Path" />
          </v-col>
          <v-col cols="6">
            <gw-autocomplete
              v-model="formData.brand"
              :items="brands"
              label="Brand"
              outlined
              dense
              hide-details
              rules="required"
              :readonly="modalLoading">
            </gw-autocomplete>
          </v-col>
          <v-col cols="6">
            <gw-text-field
              v-model="formData.available"
              :rules="textFieldRules"
              :readonly="modalLoading"
              outlined
              dense
              hide-details
              label="Available" />
          </v-col>
          <v-col cols="6">
            <gw-autocomplete
              v-model="formData.category"
              :items="autoCompleteCategories"
              label="Categories"
              outlined
              dense
              hide-details
              rules="required"
              :readonly="modalLoading">
            </gw-autocomplete>
          </v-col>
          <v-col cols="12">
            <div class="tags-container mb-1">
              <v-chip
                v-for="(tag, index) in formData.tags"
                :key="`tag-selected-chip-${index}`"
                class="ma-1"
                color="secondary"
                text-color="white"
                small
                label
                close
                @click:close="unSelectTag(index)">
                {{ tag }}
              </v-chip>
            </div>
            <v-autocomplete
              v-model="selectedTags"
              :items="autoCompleteTags"
              :search-input.sync="tagSearch"
              label="Tags"
              multiple
              outlined
              dense
              hide-details
              hide-selected
              :readonly="modalLoading">
              <template v-slot:append>
                <v-icon x-small>
                  mdi-tag
                </v-icon>
              </template>
              <template v-slot:selection>
                <span></span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="formData.useTag"
              label="Use Tag"
              dense />
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="formData.countDownImage"
              label="Count Down Image"
              dense />
          </v-col>
          <v-col cols="12">
            <gw-drop-file
              v-model="formData.image"
              :loading="modalLoading"
              :img-aspect-ratio="0.75"
              label="Image"
              s3-path="/gw-web-banner" />
          </v-col>
          <v-col cols="12">
            <gw-drop-file
              v-model="formData.socialImage"
              :loading="modalLoading"
              :img-aspect-ratio="0.75"
              label="Social Image"
              s3-path="/gw-web-banner" />
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="formData.isOnWeb"
              label="On Web"
              dense />
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="formData.isOnBot"
              label="On Bot"
              dense />
          </v-col>
          <v-col cols="12">
            <gw-text-field
              v-model="formData.priority"
              :rules="textFieldRules"
              readonly
              outlined
              dense
              hide-details
              label="Priority" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="blue darken-1"
        text
        @click="closeFormModal()">
        Cancel
      </v-btn>
      <v-btn
        :loading="modalLoading"
        color="blue darken-1"
        type="submit"
        text>
        Save
      </v-btn>
    </v-card-actions>
  </validation-observer>
</template>

<script>

  export default {
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      title: {
        type: String,
        default: ''
      },
      tags: {
        type: Array,
        default: () => []
      },
      categories: {
        type: Array,
        default: () => []
      },
      brands: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      modalLoading: false,
      textFieldRules: {
        required: true
      },
      tagSearch: ''
    }),
    computed: {
      formData: {
        get () {
          return this.value
        },
        set (val) {
          this.value = val
          this.$emit('input', this.value)
        }
      },
      autoCompleteCategories () {
        return this.categories.map((category) => category.name)
      },
      autoCompleteTags () {
        return this.tags.map((tag) => tag.name)
      },
      selectedTags: {
        get () {
          return this.formData.tags
        },
        set (val) {
          this.formData.tags = val
          this.tagSearch = ''
        }
      }
    },
    methods: {
      unSelectTag (index) {
        const tagIndex = this.tags.findIndex((tag) => tag.name === this.formData.tags[index])
        if (tagIndex !== -1) {
          this.formData.tags.splice(index, 1)
        }
      },
      async save () {
        this.modalLoading = true
        const valid = await this.$refs.attributeForm.validate()
        if (valid) {
          this.$emit('on-save')
        }
        this.modalLoading = false
      },
      closeFormModal () {
        this.$emit('on-close')
      }
    }
  }
</script>

<style scoped>
.tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
</style>
