<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="cartLimitations"
      :items-per-page="-1"
      :loading="loading || gettingTags"
      height="70vh"
      class="elevation-3"
      hide-default-footer>
      <template v-slot:top>
        <v-toolbar flat>
          <v-row
            justify="space-between"
            no-gutters>
            <v-col cols="3">
              <v-toolbar-title>
                <span class="capitalize">
                  Cart Limitation
                </span>
              </v-toolbar-title>
            </v-col>
            <v-col cols="9">
              <v-row
                justify="end"
                no-gutters>
                <v-col cols="3">
                  <v-select
                    v-model="selectedBrand"
                    :items="brands"
                    item-text="text"
                    item-value="value"
                    label="Brand"
                    color="secondary"
                    dense
                    outlined
                    hide-details
                    class="mr-1" />
                </v-col>
                <v-col cols="4">
                  <search-box
                    v-model="inputSearch"
                    class="ml-1"
                    @on-search="getCartLimitations()" />
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    class="ml-2"
                    color="primary"
                    @click="dialogToggle()">
                    Create
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-dialog
            v-if="dialog"
            v-model="dialog"
            max-width="500px">
            <v-card>
              <cart-limitation-form-data
                v-model="formData"
                :title="formTitle"
                :tags="tags"
                :brands="brands"
                @on-save="saveForm()"
                @on-close="closeFormModal()" />
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.tags`]="{ item }">
        <v-chip
          v-for="(tag, tIndex) in item.tags"
          :key="`tag-chip-${tIndex}`"
          class="ma-1"
          color="secondary"
          text-color="white"
          small
          label>
          {{ tag }}
        </v-chip>
      </template>
      <template v-slot:[`item.startDate`]="{ item }">
        {{ getDateFormat(item.startDate) }}
      </template>
      <template v-slot:[`item.endDate`]="{ item }">
        {{ getDateFormat(item.endDate) }}
      </template>
      <template v-slot:[`item.isPerProduct`]="{ item }">
        <v-icon
          v-if="item.isPerProduct"
          color="success">
          mdi-check
        </v-icon>
        <v-icon
          v-else
          color="error">
          mdi-close
        </v-icon>
      </template>
      <template v-slot:[`item.isPerTags`]="{ item }">
        <v-icon
          v-if="item.isPerTags"
          color="success">
          mdi-check
        </v-icon>
        <v-icon
          v-else
          color="error">
          mdi-close
        </v-icon>
      </template>
      <template v-slot:[`item.note`]="{ item }">
        {{ item.note }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          class="mr-2"
          small
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <gw-icon-hover
          class="sku-table-bin"
          icon-name="mdi-delete"
          icon-hover="mdi-delete-empty"
          small
          @click="removeCartLimitation(item)" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import WebManagementProvider from '@/resources/WebManagementProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import CartLimitationFormData from './CartLimitationFormData.vue'

const ProductAttributeService = new ProductAttributeProvider()
const WebManagementService = new WebManagementProvider()

export default {
  components: { SearchBox, CartLimitationFormData },
  data () {
    return {
      headers: [
        {
          text: 'Tags',
          value: 'tags',
          align: 'center',
          sortable: true
        },
        {
          text: 'Start Date',
          value: 'startDate',
          align: 'center',
          sortable: false
        },
        {
          text: 'End Date',
          value: 'endDate',
          align: 'center',
          sortable: false
        },
        {
          text: 'Per Product',
          value: 'isPerProduct',
          align: 'center',
          sortable: false
        },
        {
          text: 'Per Tags',
          value: 'isPerTags',
          align: 'center',
          sortable: false
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'center',
          sortable: false
        },
        {
          text: 'Brand',
          value: 'brand',
          align: 'center',
          sortable: false
        },
        {
          text: 'Note',
          value: 'note',
          align: 'center',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '100px',
          align: 'start'
        }
      ],
      inputSearch: '',
      selectedBrand: null,
      brands: [],
      dialog: false,
      isEdited: false,
      loading: false,
      gettingTags: false,
      formData: {
        id: '',
        tags: [],
        startDate: null,
        endDate: null,
        isPerProduct: true,
        isPerTags: false,
        amount: 1,
        note: '',
        brand: null,
        status: 'active'
      },
      tags: [],
      cartLimitations: []
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Cart Limitation' : 'New Cart Limitation'
    }
  },
  watch: {
    selectedBrand (val) {
      this.formData.brand = val
      this.getCartLimitations()
    }
  },
  async mounted () {
    await Promise.all([
      this.getTags(),
      this.getCartLimitations(),
      this.getBrand()
    ])
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    async getBrand () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 999
        })

        this.brands = data.results.map((brand) => ({
            text: brand.name,
            value: brand.name
          }))
      } catch (error) {
        console.error('getAttribute: ', error)
      }
    },
    getDateFormat (date) {
      return this.$dayjs(date).format('DD/MM/YYYY HH:mm')
    },
    dialogToggle () {
      this.clearForm()
      this.dialog = true
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.clearForm()
    },
    editItem (item) {
      this.isEdited = true
      this.formData = JSON.parse(JSON.stringify(item))
      this.dialog = true
    },
    clearForm () {
      this.formData = {
        id: '',
        tags: [],
        startDate: null,
        endDate: null,
        isPerProduct: true,
        isPerTags: false,
        amount: 1,
        note: '',
        brand: this.selectedBrand,
        status: 'active'
      }
    },
    async getTags () {
      try {
        this.gettingTags = true

        const { data } = await ProductAttributeService.getProductAttribute('tags', {
          page: 1,
          itemsPerPage: 9999,
          sortBy: ['id'],
          sortDesc: [true]
        })

        this.tags = data.results.map((r) => ({
          id: r.id,
          name: r.name,
          status: 'active'
        }))
      } catch (error) {
        console.error('getTags: ', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.gettingTags = false
      }
    },
    async getCartLimitations () {
      try {
        this.loading = true

        const { data } = await WebManagementService.getCartLimitations({
          search: this.inputSearch,
          brand: this.selectedBrand
        })
        this.cartLimitations = data
      } catch (error) {
        console.error('getCartLimitations', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    removeCartLimitation (item) {
      this.setModal({
        value: true,
        title: 'Remove Cart Limitation',
        message: 'Do you want to remove the Cart Limitation?',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveCartLimitation(item.id)
      })
    },
    async confirmRemoveCartLimitation (id) {
      try {
        this.loading = true

        const { data } = await WebManagementService.deleteCartLimitation(id)

        if (data) {
          this.setSnackbar({
            value: true,
            message: 'Cart Limitation removed.',
            type: 'success'
          })

          this.getCartLimitations()
        } else {
          const error = {
            code: 400,
            message: 'Cart Limitation ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        console.error('confirmRemoveCartLimitation', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async saveForm () {
      this.setLoading({
        active: true,
        clickAble: false,
        message: 'Saving...'
      })

      let isSuccess = false
      let message = 'created'
      try {
        if (this.isEdited) {
          const { data } = await WebManagementService.updateCartLimitation(this.formData)

          if (data) {
            isSuccess = true
          }
          message = 'updated'
        } else {
          const { data } = await WebManagementService.createCartLimitation(this.formData)

          if (data) {
            isSuccess = true
          }
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
        console.error('saveForm', error)
      } finally {
        this.setLoading({ active: false })

        if (isSuccess) {
          this.setSnackbar({
            value: true,
            message: `Cart Limitation ${message}`,
            type: 'success'
          })

          this.closeFormModal()
          this.getCartLimitations()
        } else {
          this.setSnackbar({
            value: true,
            message: `Cart Limitation ${message} failed.`,
            type: 'error'
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.mini-image {
  cursor: pointer;
}
</style>
