<template>
  <validation-observer
    ref="cartLimitationForm"
    tag="form"
    @submit.prevent="save()">
    <v-card-title>
      <span class="headline">{{ title }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="tags-container mb-1">
              <v-chip
                v-for="(tag, index) in formData.tags"
                :key="`tag-selected-chip-${index}`"
                class="ma-1"
                color="secondary"
                text-color="white"
                small
                label
                close
                @click:close="unSelectTag(index)">
                {{ tag }}
              </v-chip>
            </div>
            <v-autocomplete
              v-model="selectedTags"
              :items="tags"
              :search-input.sync="tagSearch"
              label="Tags"
              color="secondary"
              multiple
              outlined
              dense
              hide-details
              hide-selected
              item-text="name"
              item-value="name"
              :readonly="modalLoading">
              <template v-slot:append>
                <v-icon x-small>
                  mdi-tag
                </v-icon>
              </template>
              <template v-slot:selection>
                <span></span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <date-time-picker
              v-model="formData.startDate"
              label="Start date"
              @on-submit="onStartDateSubmit($event)" />
          </v-col>
          <v-col cols="12">
            <date-time-picker
              v-model="formData.endDate"
              label="End date"
              @on-submit="onEndDateSubmit($event)" />
          </v-col>
          <v-col cols="6">
            <gw-autocomplete
              v-model="formData.brand"
              :items="brands"
              item-text="text"
              item-value="value"
              label="Brand"
              outlined
              dense
              hide-details
              rules="required"
              color="secondary"
              :readonly="modalLoading">
            </gw-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="formData.amount"
              :readonly="modalLoading"
              :rules="textFieldRules"
              type="number"
              outlined
              dense
              hide-details
              label="Amount"
              color="secondary"
              required />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="formData.isPerProduct"
              :readonly="modalLoading"
              label="Per Product"
              class="mt-0"
              color="secondary"
              hide-details
              :disabled="formData.isPerTags" />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="formData.isPerTags"
              :readonly="modalLoading"
              label="Per Tags"
              class="mt-0"
              color="secondary"
              hide-details />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="formData.note"
              :readonly="modalLoading"
              outlined
              dense
              hide-details
              label="Note"
              color="secondary" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="blue darken-1"
        text
        @click="closeFormModal()">
        Cancel
      </v-btn>
      <v-btn
        :loading="modalLoading"
        color="blue darken-1"
        type="submit"
        text>
        Save
      </v-btn>
    </v-card-actions>
  </validation-observer>
</template>

<script>
import DateTimePicker from './DateTimePicker.vue'

export default {
components: { DateTimePicker },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    tags: {
      type: Array,
      default: () => []
    },
    brands: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      modalLoading: false,
      textFieldRules: [
        (v) => !!v || 'Field is required!'
      ],
      tagSearch: ''
    }
  },
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.value = val
        this.$emit('input', this.value)
      }
    },
    selectedTags: {
      get () {
        return this.formData.tags
      },
      set (val) {
        this.formData.tags = val
        this.tagSearch = ''
      }
    }
  },
  methods: {
    unSelectTag (index) {
      const tagIndex = this.tags.findIndex((tag) => tag.name === this.formData.tags[index])
      if (tagIndex !== -1) {
        this.formData.tags.splice(index, 1)
      }
    },
    onStartDateSubmit (date) {
      this.formData.startDate = date.utc
    },
    onEndDateSubmit (date) {
      this.formData.endDate = date.utc
    },
    async save () {
      this.modalLoading = true
      const valid = await this.$refs.cartLimitationForm.validate()
      if (valid) {
        this.$emit('on-save')
      }
      this.modalLoading = false
    },
    closeFormModal () {
      this.$emit('on-close')
    }
  }
}
</script>

<style scoped>
.tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
</style>
